.container {
    margin: 0px auto;
    border-radius: 10px;
    /* border: 1px solid red; */
}
.gridView {
    display: grid;
    grid-template-columns: repeat(5, 250px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: auto auto;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    padding: 6px 6px;
    border: 1px solid rgba(93, 93, 93, 0.128);
    /* background-color: rgb(55, 54, 54);    */
    box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
    animation: levitate 1 linear forward;
}

.gridViewSmall {
    display: grid;
    grid-template-columns: repeat(5, 150px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: auto auto;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    padding: 6px 6px;
    border: 1px solid rgba(93, 93, 93, 0.128);
    /* background-color: rgb(55, 54, 54);    */
    box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
    animation: levitate 1 linear forward;
}

@keyframes levitate {
    from {
        box-shadow: none
    } to {
        box-shadow: 0px 0px 5px rgba(33, 230, 237, 0.418);
    }
}

.emptyArt {
    width: 140px;
    height: 132px;
    background-color: #303030;
    /* border: 1px solid red; */
}
.emptyArt:hover {
    background-color: transparent;    
}
.centerScale {
    width: 250px;
    height: 250px;
    /* border: 1px solid blue; */
}
.rightScale {
    width: 250px;
    height: 250px;
    /* border: 1px solid red; */
}
.centerScaleSmall {
    width: 150px;
    height: 150px;
    /* border: 1px solid red; */
}
.rightScaleSmall {
    width: 150px;
    height: 150px;
    /* border: 1px solid red; */
}

.artHover:hover {
    cursor: pointer;
    border-radius: 5px;
    /* box-shadow: 3px 3px 50px black; */
    /* animation: scaleUp 0.2s linear forwards; */
}
.artHoverHover:hover {
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 3px 3px 50px black;
    animation: scaleUp 0.2s linear forwards;
}

.artHover:not(:hover) {
    animation: scaleDown 0.3s linear backwards;
}
.artHover:not(:hover) {
    animation: none;
}


@keyframes scaleUp {
    from {
        transform: scale(1.0);

    } 
    to {
        transform: scale(5.0);
        backdrop-filter: blur(20px);
    }
}

@keyframes scaleDown {
    from {
        transform: scale(1.5);
    } 
    to {
        transform: scale(1.0);
    }
}

.artOverlay {
    z-index: 9999;
    position: fixed;
    visibility: hidden;
    padding: 8px 20px;
    border-radius: 20px;
    opacity: 0.85;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);
    font-size: 12px;
}

.artDetails {
    visibility: hidden;
}


.artistName {
    font-weight: bold;
    color: white;
}

.artistLink {
    color: whitesmoke;
}

.letsAdmire {
    width: 100%;
    margin: auto auto;
    /* margin-top: -40px; */
}
.letsAdmire p {
    padding-bottom: 20px;    
    color: #c9c9c9;
    font-size: 15px;
    line-height: 1.7;
}

/* RESPONSIVENESS */
/* Phone */
@media (max-width: 520px) and (max-width: 1400px) {
    .gridView {
        display: grid;
        grid-template-columns: repeat(5, 80px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: auto auto;
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        padding: 6px 6px;
        border: 1px solid rgba(93, 93, 93, 0.128);
        /* background-color: rgb(55, 54, 54);    */
        box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
        animation: levitate 1 linear forward;
    }
    .gridViewSmall {
        display: grid;
        grid-template-columns: repeat(5, 150px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: auto auto;
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        padding: 6px 6px;
        border: 1px solid rgba(93, 93, 93, 0.128);
        /* background-color: rgb(55, 54, 54);    */
        box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
        animation: levitate 1 linear forward;
    }
    .centerScale {
        width: 80px;
        height: 80px;
        /* border: 1px solid red; */
    }
    .rightScale {
        width: 80px;
        height: 80px;
        /* border: 1px solid red; */
    }
    .centerScaleSmall {
        width: 80px;
        height: 80px;
        /* border: 1px solid red; */
    }
    .rightScaleSmall {
        width: 80px;
        height: 80px;
        /* border: 1px solid red; */
    }
    .letsAdmire {
        width: 100%;
        margin: auto auto;
        margin-top: -40px;
    }
    .letsAdmire p {
        width: 88%;
        margin: auto auto;
        padding-top: 20px;
    }
    
}

/* Tablet */
@media (min-width: 521px) and (max-width: 1400px) {
    .gridView {
        display: grid;
        grid-template-columns: repeat(5, 180px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: auto auto;
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        padding: 6px 6px;
        border: 1px solid rgba(93, 93, 93, 0.128);
        /* background-color: rgb(55, 54, 54);    */
        box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
        animation: levitate 1 linear forward;
    }
    .gridViewSmall {
        display: grid;
        grid-template-columns: repeat(5, 180px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: auto auto;
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        padding: 6px 6px;
        border: 1px solid rgba(93, 93, 93, 0.128);
        /* background-color: rgb(55, 54, 54);    */
        box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
        animation: levitate 1 linear forward;
    }
    .centerScale {
        width: 180px;
        height: 180px;
        /* border: 1px solid red; */
    }
    .rightScale {
        width: 180px;
        height: 180px;
        /* border: 1px solid red; */
    }
    .centerScaleSmall {
        width: 180px;
        height: 180px;
        /* border: 1px solid red; */
    }
    .rightScaleSmall {
        width: 180px;
        height: 180px;
        /* border: 1px solid red; */
    }
    .letsAdmire {
        width: 100%;
        margin: auto auto;
        margin-top: -40px;
    }
    .letsAdmire p {
        width: 88%;
        margin: auto auto;
        padding-top: 20px;
    }
    
}

/* Smaller Phones */
@media (max-width: 400px) and (max-width: 1400px) {
    .gridView {
        display: grid;
        grid-template-columns: repeat(5, 60px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: auto auto;
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        padding: 6px 6px;
        border: 1px solid rgba(93, 93, 93, 0.128);
        /* background-color: rgb(55, 54, 54);    */
        box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
        animation: levitate 1 linear forward;
    }
    .gridViewSmall {
        display: grid;
        grid-template-columns: repeat(5, 150px);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: auto auto;
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        padding: 6px 6px;
        border: 1px solid rgba(93, 93, 93, 0.128);
        /* background-color: rgb(55, 54, 54);    */
        box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.419);
        animation: levitate 1 linear forward;
    }
    .centerScale {
        width: 60px;
        height: 60px;
        /* border: 1px solid red; */
    }
    .rightScale {
        width: 60px;
        height: 60px;
        /* border: 1px solid red; */
    }
    .centerScaleSmall {
        width: 60px;
        height: 60px;
        /* border: 1px solid red; */
    }
    .rightScaleSmall {
        width: 60px;
        height: 60px;
        /* border: 1px solid red; */
    }
    .letsAdmire {
        width: 100%;
        margin: auto auto;
        margin-top: -40px;
    }
    .letsAdmire p {
        width: 88%;
        margin: auto auto;
        padding-top: 20px;
    }
    
}


