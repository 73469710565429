.footerStyle {
    /* height: 50px; */
    color: white;
    text-align: center;
    padding: 20px 0px;
    font-size: 15px;
    /* font-weight: bold; */
    background-color: #101010;
    position: relative;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-top: 100px;
}