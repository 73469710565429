.About {
    margin: auto auto;
    width: 45%;
    text-align: center;
    padding: 80px 0px 50px;
    /* border: 1px solid red; */
}

.Download {
    margin: auto auto;
    width: 35%;
    text-align: center;
    padding: 150px 0px 50px;
    /* border: 1px solid red; */
}

.Contribute {
    margin: auto auto;
    width: 37%;
    text-align: center;
    padding: 120px 0px;
    /* border: 1px solid red; */
}

.Contact {
    margin: auto auto;
    width: 50%;
    text-align: center;
    padding: 50px 0px;
    /* border: 1px solid red; */
}
.Controls {
    margin: auto auto;
    width: 30%;
    text-align: center;
    margin: 0px auto 80px;
    padding: 15px ;
    border-radius: 20px;
    background-color: rgb(252, 252, 252);
    border: 1px solid transparent;
}
.Controls:hover {
    border: 1px solid grey;
}
.ControlsDark {
    margin: auto auto;
    width: 30%;
    text-align: center;
    margin: 0px auto 80px;
    padding: 15px ;
    border-radius: 20px;
    background-color: #272727;
    border: 1px solid transparent;
    /* border: solid 1px;/
    clip-path: inset(0 round 1px); */
}
.ControlsDark:hover {
    border: 1px solid rgb(86, 86, 86);
    border-radius: 20px;
    /* z-index: 9999;
    border: solid 1px;
    border-image: linear-gradient(90deg, purple, orange) 1;
    clip-path: inset(0 round 2px); */
}
.controlTitleHover:hover {
    color: aqua;
}
.controlTitleHoverDark:hover {
    color: rgb(107, 115, 252);
}

.ThankYou {
    color: whitesmoke;
    font-size: 40px;
    font-weight: bold;
}
.ThankYouDark {
    color: black;
    font-size: 40px;
    font-weight: bold;
}

.title {
    color: whitesmoke;
    font-size: 22px;
    font-weight: bold;
}
.titleDark {
    color: black;
    font-size: 22px;
    font-weight: bold;
}

.description {
    color: #c9c9c9;
    font-size: 17px;
    line-height: 1.7;
}
.descriptionDark {
    color: #202020;
    font-size: 17px;
    line-height: 1.7;
}

.downloadBtns {
    width: 60%;
    margin: auto auto;
    padding-top: 10px;
}
.downloadBtn {
    border: none;
    border-radius: 20px;
    background-color: rgb(19, 137, 254);
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    width: 400px;
}
.downloadBtn:hover {
    background-color: cyan;
    transform: scale(1.005);
}
.downloadBtnText {
    color: white;
}
.downloadBtnText:hover {
    color: black;
}
.downloadBtnTextDark {
    color: black;
}

.socialMedias {
    display: inline;
}

.socialMediaLogo {
    width: 35px;
    height: 35px;
    margin: 20px 12px;
}

.socialMediaLogo:hover {
    animation: scaleUp 0.3s linear forwards;
}

.socialMediaLogo:not(:hover) {
    animation: scaleDown 0.3s linear backwards;
}

@keyframes scaleUp {
    from {
        transform: scale(1.0);

    } 
    to {
        transform: scale(1.5);
        backdrop-filter: blur(20px);
    }
}

@keyframes scaleDown {
    from {
        transform: scale(1.5);
    } 
    to {
        transform: scale(1.0);
    }
}

.eachControl {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto 10px;
    padding: 10px 15px 10px 20px;
    border-radius: 50px;
    /* box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.219); */
    background-color: whitesmoke;
    /* border: 1px solid rgba(93, 93, 93, 0.128); */
    /* border: 1px solid blue; */
}
.eachControlDark {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto 10px;
    padding: 10px 15px 10px 20px;
    border-radius: 50px;
    /* box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.219); */
    background-color: rgb(59, 59, 59);
    /* border: 1px solid rgba(93, 93, 93, 0.128); */
    /* border: 1px solid blue; */
}
.eachControl:hover {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto 10px;
    padding: 10px 15px 10px 20px;
    border-radius: 50px;
    background-color: rgb(224, 224, 224);
}
.eachControlDark:hover {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto 10px;
    padding: 10px 15px 10px 20px;
    border-radius: 50px;
    background-color: rgba(128, 128, 128, 0.239);
}
.eachControl:hover .controlTitle {
    color: black;
    font-size: 16px;
}
.eachControlDark:hover .controlTitleDark {
    color: whitesmoke;
    font-size: 16px;
}

.controlTitle {
    color: black;
    font-size: 16px;
    /* line-height: 1.5; */
}

.controlTitleDark {
    color: rgb(220, 220, 220);
    font-size: 16px;
    /* line-height: 1.5; */
}

.visitors {
    font-size: 20px;
}



/*  RESPONSIVENESS */

/* Phones */
@media (max-width: 500px) {
    .About {
        margin: auto auto;
        width: 88%;
        text-align: center;
        padding: 20px 0px 20px;
        /* border: 1px solid red; */
    }
    .Controls {
        margin: auto auto;
        width: 88%;
        text-align: center;
        margin: 0px auto 30px;
        padding: 15px ;
        border-radius: 20px;
        background-color: rgb(252, 252, 252);
        border: 1px solid transparent;
    }
    .ControlsDark {
        margin: auto auto;
        width: 88%;
        text-align: center;
        margin: 0px auto 30px;
        padding: 15px ;
        border-radius: 20px;
        background-color: #272727;
        border: 1px solid transparent;
    }
    .Download {
        margin: auto auto;
        width: 88%;
        text-align: center;
        padding: 150px 0px 50px;
        /* border: 1px solid red; */
    }

    .Contribute {
        margin: auto auto;
        width: 88%;
        text-align: center;
        padding: 120px 0px;
        /* border: 1px solid red; */
    }
    .Contact {
        margin: auto auto;
        width: 88%;
        text-align: center;
        padding: 50px 0px;
        /* border: 1px solid red; */
    }
    .downloadBtns {
        width: 88%;
        margin: auto auto;
        padding-top: 0px;
    }
    .downloadBtn {
        border: none;
        border-radius: 20px;
        background-color: rgb(58, 149, 240);
        padding: 10px 5px;
        margin-bottom: 10px;
        font-size: 14px;
        width: 330px;
    }
    .eachControl {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        /* box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.219); */
        background-color: whitesmoke;
        /* border: 1px solid rgba(93, 93, 93, 0.128); */
        /* border: 1px solid blue; */
    }
    .eachControl:first-child {
        display: none;
    }
    .eachControlDark {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        /* box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.219); */
        background-color: rgb(59, 59, 59);
        /* border: 1px solid rgba(93, 93, 93, 0.128); */
        /* border: 1px solid blue; */
    }
    .eachControlDark:first-child {
        display: none;
    }
    .eachControl:hover {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        background-color: rgb(224, 224, 224);
    }
    .eachControlDark:hover {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        background-color: rgba(128, 128, 128, 0.239);
    }
    .title {
        color: whitesmoke;
        font-size: 18px;
        font-weight: bold;
    }
    .titleDark {
        color: black;
        font-size: 18px;
        font-weight: bold;
    }
    .description {
        color: #c9c9c9;
        font-size: 14px;
        line-height: 1.7;
    }
    .descriptionDark {
        color: #202020;
        font-size: 14px;
        line-height: 1.7;
    }
    .Download {
        margin: auto auto;
        /* width: 35%; */
        text-align: center;
        padding: 60px 0px 0px;
        /* border: 1px solid red; */
    }
    .Contribute {
        margin: auto auto;
        /* width: 37%; */
        text-align: center;
        padding: 50px 0px;
        /* border: 1px solid red; */
    }
    .Contact {
        margin: auto auto;
        /* width: 50%; */
        text-align: center;
        padding: 0px 0px 50px;
        /* border: 1px solid red; */
    }
    .visitors {
        font-size: 15px;
    }

}

/* Tablets */
@media (min-width: 521px) and (max-width: 1400px) {
    .About {
        margin: auto auto;
        width: 70%;
        text-align: center;
        padding: 50px 0px 30px;
        /* border: 1px solid red; */
    }
    .Controls {
        margin: auto auto;
        width: 50%;
        text-align: center;
        margin: 0px auto 50px;
        padding: 15px ;
        border-radius: 20px;
        background-color: rgb(252, 252, 252);
        border: 1px solid transparent;
    }
    .ControlsDark {
        margin: auto auto;
        width: 50%;
        text-align: center;
        margin: 0px auto 50px;
        padding: 15px ;
        border-radius: 20px;
        background-color: #272727;
        border: 1px solid transparent;
    }
    .Download {
        margin: auto auto;
        width: 70%;
        text-align: center;
        padding: 100px 0px 0px;
        /* border: 1px solid red; */
    }

    .Contribute {
        margin: auto auto;
        width: 70%;
        text-align: center;
        padding: 80px 0px;
        /* border: 1px solid red; */
    }
    .Contact {
        margin: auto auto;
        width: 88%;
        text-align: center;
        padding: 50px 0px;
        /* border: 1px solid red; */
    }
    .downloadBtns {
        width: 88%;
        margin: auto auto;
        padding-top: 0px;
    }
    .downloadBtn {
        border: none;
        border-radius: 20px;
        background-color: rgb(19, 137, 254);
        padding: 10px 20px;
        margin-bottom: 10px;
        font-size: 16px;
        width: 360px;
    }
    .eachControl {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        /* box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.219); */
        background-color: whitesmoke;
        /* border: 1px solid rgba(93, 93, 93, 0.128); */
        /* border: 1px solid blue; */
    }
    .eachControl:first-child {
        display: none;
    }
    .eachControlDark {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        /* box-shadow: 0px 0px 50px rgba(44, 44, 45, 0.219); */
        background-color: rgb(59, 59, 59);
        /* border: 1px solid rgba(93, 93, 93, 0.128); */
        /* border: 1px solid blue; */
    }
    .eachControlDark:first-child {
        display: none;
    }
    .eachControl:hover {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        background-color: rgb(224, 224, 224);
    }
    .eachControlDark:hover {
        width: 88%;
        display: flex;
        justify-content: space-between;
        margin: 0px auto 10px;
        padding: 10px 15px 10px 20px;
        border-radius: 50px;
        background-color: rgba(128, 128, 128, 0.239);
    }
    .title {
        color: whitesmoke;
        font-size: 20px;
        font-weight: bold;
    }
    .titleDark {
        color: black;
        font-size: 20px;
        font-weight: bold;
    }
    .description {
        color: #c9c9c9;
        font-size: 14px;
        line-height: 1.7;
    }
    .descriptionDark {
        color: #202020;
        font-size: 14px;
        line-height: 1.7;
    }
    .Contact {
        margin: auto auto;
        /* width: 50%; */
        text-align: center;
        padding: 0px 0px 50px;
        /* border: 1px solid red; */
    }
    .visitors {
        font-size: 18px;
    }
}


